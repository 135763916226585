<template>
  <el-button class="elv-base-button-export" link @click="onExport({})">
    <SvgIcon name="reports-export" :width="props.iconWidth" :height="props.iconHeight" />
    <span class="elv-base-button-export__text">{{ transformI18n(props.text) || t('button.export') }}</span>
  </el-button>

  <ElvMessageBox
    ref="exportMessageBoxRef"
    :confirmButtonText="t('button.confirm')"
    :cancelButtonText="t('button.cancel')"
    :title="t('title.exportData')"
    :loading="exportLoading"
    @onCancelEvent="onCancelExportData"
    @onConfirmEvent="onConfirmExportData"
  >
    <template #content>
      <span class="elv-confirm-export-info">{{ t('message.exportDataInfo') }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { transformI18n } from '@/i18n'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { exportProgressStatusType } from '@/config/global'
import { useEntityStore } from '@/stores/modules/entity'

defineOptions({
  inheritAttrs: false
})

type ParamsType = {
  type: string
  extra: Object
}

const props = defineProps({
  noPermission: {
    type: Boolean,
    required: true
  },
  params: {
    type: Object as () => ParamsType,
    required: true
  },
  text: {
    type: String,
    default: ''
  },
  textColor: {
    type: String,
    default: '#1e2024'
  },
  textWeight: {
    type: Number,
    default: 500
  },
  iconWidth: {
    type: String,
    default: '16'
  },
  iconHeight: {
    type: String,
    default: '16'
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const exportLoading = ref(false)
const exportParams: any = ref({})
const exportMessageBoxRef = useTemplateRef('exportMessageBoxRef')

const entityId = computed(() => {
  return route.params?.entityId as string as string
})

/**
 * @description: 导出数据
 * @param {*} data
 */
const onExport = (data: any) => {
  if (props.noPermission) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (isEmpty(data)) {
    exportParams.value = {}
  } else {
    exportParams.value = data
  }
  exportMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description 检测是否需要更新导出任务，进行导出任务通知更新
 */
const updateExportDataHistory = () => {
  const containsExportType = Object.values(exportProgressStatusType).some((item) => item.value === props.params.type)
  if (containsExportType) {
    entityStore.updateExportTaskHistory(entityId.value, props.params.type)
  }
}

/**
 * @description: 确认导出数据
 */
const onConfirmExportData = async () => {
  try {
    exportLoading.value = true
    const data = isEmpty(exportParams.value) ? props.params : exportParams.value
    await ProjectApi.exportExcelData(entityId.value, data)
    updateExportDataHistory()
    ElMessage.success(t('message.success'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    exportLoading.value = false
    exportMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}

/**
 * @description: 取消导出数据
 */
const onCancelExportData = () => {
  exportMessageBoxRef.value?.onCheckMessageBoxDialog()
}

defineExpose({
  onExport
})
</script>

<style lang="scss" scoped>
.elv-base-button-export {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: v-bind('textWeight');
  font-size: 12px;
  line-height: 15px;
  color: v-bind('textColor');
  cursor: pointer;
  margin-left: 8px;

  span {
    margin-left: 8px;
  }

  svg {
    fill: #838d95;
  }

  &:hover {
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }
}
</style>
